/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */

@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// .ag-theme-alpine {
//   font-size: 13.4px;
//   font-family: 'Source Sans Pro', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !important;
//   /*color: black !important;*/
//   color: rgb(34,34,34) !important;
//   font-weight: 300 !important;
// }
// .ag-header-cell-text {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   font-size: 13px;
//   font-weight: 600;
//   color: rgb(34,34,34) !important;
// }
.ag-header-cell-text {
  font-family: 'Source Sans Pro', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-theme-alpine {
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-theme-alpine .ag-column-drop-empty-message,
.ag-theme-alpine-dark .ag-column-drop-empty-message,
.ag-theme-alpine-auto-dark .ag-column-drop-empty-message {
  font-size: 15px;
  color: #4a4a4a;
}

.rowcoloragtheme .ag-row {
  border-right-width: 1px !important;
  border-top-width: 1px !important;
  border-right-style: solid !important;
  border-top-style: solid !important;
  border-right-color: #006fbf !important;
  border-top-color: #006fbf !important;
}

.ng-tns-c70-2 {
  font-size: 14px !important;
}

button {
  font-size: 14px !important;
}

body .ag-theme-alpine .ag-cell {
  font-family: 'Source Sans Pro', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-header-cell-text {
  font-family: 'Source Sans Pro', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-header-cell-sortable .ag-header-cell-label {
  cursor: pointer;
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-paging-panel {
  font-size: 15px;
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-side-button-label {
  font-size: 14px;
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-theme-alpine .ag-column-drop-empty-message,
.ag-theme-alpine-dark .ag-column-drop-empty-message,
.ag-theme-alpine-auto-dark .ag-column-drop-empty-message {
  font-size: 15px;
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-select-agg-func-item,
.ag-column-select-column-label {
  font-size: 15px;
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-ltr .ag-side-bar-left .ag-side-button-button,
.ag-rtl .ag-side-bar-right .ag-side-button-button {
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.ag-header-cell,
.ag-header-group-cell {
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", "Arial", sans-serif !important;
  font-size: 15px !important;
  color: #4a4a4a;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
  background-color: #E6E6E6;
  content: "";
  position: absolute;
  height: 4px;
  width: calc(100% - 50px);
  top: -25px;
  left: calc(50% + 25px);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
  top: -50px;
  left: calc(50% - 25px);
  position: absolute;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: 46px;
  transition: 0.25s;
  border-radius: 100%;
  border: 2px solid #808080;
  color: #006fbf;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  border: 2px solid #339933;
  color: #339933;
  background: #D1F1DA;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing .step-indicator {
  border: 2px solid #FF0000 !important;
  color: #FF0000 !important;
}

@media (max-width: 767px) {
  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    height: 3px;
    width: calc(100% - 30px);
    top: -35px;
    left: calc(50% + 6px);
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
    top: -50px;
    left: calc(50% - 25px);
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 27px;
    transition: 0.25s;
    border-radius: 100%;
    border: 2px solid #808080;
    color: #006fbf;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    // background-color: #D1F1DA;
  }


  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
    border: 2px solid #339933;
    color: #339933;
    background: #D1F1DA;
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing .step-indicator {
    border: 2px solid #FF0000 !important;
    color: #FF0000 !important;
  }


}

@media (max-width: 350px) {
  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    height: 3px;
    width: calc(100% - 18px);
    top: -39px;
    left: calc(50% + -6px);
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
    top: -50px;
    left: calc(50% - 25px);
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    line-height: 27px;
    transition: 0.25s;
    border-radius: 100%;
    border: 2px solid #808080;
    color: #006fbf;
    font-weight: bold;
    font-size: 16px;
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
    border: 2px solid #339933;
    color: #339933;
    background: #D1F1DA;
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing .step-indicator {
    border: 2px solid #FF0000 !important;
    color: #FF0000 !important;
  }
}

.otp-input {
  width: 51px !important;
  height: 55px !important;
  // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2) !important;
  padding: 10px 10px 10px 10px !important;
  background: #ffffff !important;
  color: #7a7a7a !important;
  border-color: #c0c0c0 !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 8px 8px 8px 8px !important;
  // font-family: "Open Sans" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.otp-input:focus-visible {
  border: 2px solid #75a9f9 !important;
}

// .ng-valid:focus-visible{
//   border: 2px solid #75a9f9 !important;
// }

.colors-blue-heading {
  // width: 248px;
  // height: 35px;
  color: #006fbf;
  // font-family: "Open Sans" !important;
  // font-weight: bold;
  font-size: 22px !important;
}

.inputfields {
  padding: 10px 10px 10px 10px !important;
  background: #ffffff !important;
  color: #7a7a7a !important;
  border-color: #c0c0c0 !important;
  border-width: 2px;
  border-style: solid !important;
  border-radius: 8px 8px 8px 8px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.inputfields:focus {
  // background-color: red !important;
  border: 2px solid #75a9f9 !important;
}

.inputfield-focus:focus {
  border: 2px solid #75a9f9 !important;
}

.w-289 {
  width: 289px !important;
}

.w-51 {
  width: 51px !important;
}

.w-181 {
  width: 180px !important;
}

.w-345 {
  width: 345px !important;
}

.h-55 {
  height: 55px;
}

.h-35 {
  height: 35px !important;
}

.w-276 {
  width: 276px;
}

.w-54 {
  width: 54px;
}

.wdt-100 {
  width: 100px !important;
}

.buttons {
  // width: 276px;
  height: 45px;
  padding: 10px 10px 10px 10px;
  background: #006fbf;
  color: #ffffff;
  border-color: #7a7a7a;
  border-width: 1px;
  border-style: solid;
  border-radius: 17px 17px 17px 17px;
  // font-family: "Open Sans";
  // font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.sm-buttons {
  width: 120px;
}

.backgroud-info-btn {
  width: 253px;
  background: #a0a0a0;
  color: #ffffff;
  border-color: #7a7a7a;
}

.primary-bold-text {
  color: #006fbf;
  // font-weight: bold;
  font-size: 15px;
  // text-align: left;
}

.newfontscolormain {
  color: #1f2533 !important;
  font-size: 19px !important;
  // font-weight: 600 !important;
}

.newfontscolor {
  // color: #545f70 !important;
  color: #4a4a4a;
  font-size: 15px !important;
}

.normal-text {
  // width: 356px;
  // height: 25px;
  color: #232323;
  font-weight: 400;
  font-size: 14px;
}

.h-45 {
  height: 45px;
}

.fixed-card {
  // width: 526px;
  height: 426px !important;
  padding: 8px 8px 8px 8px !important;
  background: #ffffff !important;
  border-color: #a0a0a0 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
  overflow-y: auto !important;
  // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.checkboxs {
  // width: 228px;
  // height: 45px;
  // background: #ffffff !important;
  color: #006fbf !important;
  border-color: #006fbf !important;
  border-width: 1.5px !important;
  border-style: solid !important;
  font-weight: bold !important;
  font-size: 16px !important;
  text-align: left !important;
}

.icons-font {
  font-size: 22px;
}

.scrollbar {
  width: 25px;
  height: 426px;
  background: #e6e6e6;
  border-color: #006fbf;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px 12px 12px 12px;
}

.siteaudit-scroll::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

.siteaudit-scroll::-webkit-scrollbar-thumb,
.siteaudit-scroll::-webkit-scrollbar-thumb:hover {
  background: #d2d1d1;
  border-color: #e6e6e6;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px 12px 12px 12px;
}

.bg-table-th {
  background-color: #d4e3fc !important;
}

.tables {
  border-collapse: collapse !important;
  width: 100% !important;
  border: 2px solid #75a9f9 !important;
}

.ths {
  border: 1px solid #75a9f9 !important;
  color: #232323 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 10px !important;
}

.trs {
  border-bottom: 1px solid #75a9f9 !important;
}

.tds {
  padding: 10px !important;
  border: 1px solid #75a9f9 !important;
}

.trs:nth-child(even) {
  background-color: #f2f2f2;
}

.selects {
  width: 180px !important;
  height: 45px !important;
  padding: 10px !important;
  // background: #ebebeb;
  color: #7a7a7a !important;
  border: 1px solid #c0c0c0 !important;
  border-radius: 8px 8px 8px 8px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.selects:focus {
  // background-color: red !important;
  border: 2px solid #75a9f9 !important;
  box-shadow: none !important;
}

.datepickers {
  color: #7a7a7a !important;
  border: 1px solid #c0c0c0 !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.datepickers:focus {
  border: 2px solid #75a9f9 !important;
  box-shadow: none !important;
}


aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  background-color: #64ade2 !important;
  font-size: 16px;
  color: #ffffff !important;
}

.vertical-menu {
  top: 0px !important;
}

.f-14 {
  font-size: 14px !important;
}

.page-content {
  padding: 0px !important;
  margin-top: 50px !important;
}


.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
  font-size: 1.45rem;
  margin-left: 0px !important;
  color: #ffffff !important;
}

body[data-sidebar=dark] #sidebar-menu ul li a {
  color: #ffffff !important;
  font-size: 14px !important;
  margin-left: 4px !important;
}

body[data-sidebar=dark] #sidebar-menu ul li a i {
  color: #ffffff !important;
}

.vertical-collpsed .main-content {
  margin-left: 70px !important;
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar>*>* {
    float: none;
    margin-left: -16px !important;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5em;
    display: block;
  }

  .fc .fc-toolbar h2 {
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
  }

  .fc,
  .fc *,
  .fc :after,
  .fc :before {
    box-sizing: border-box;
    text-align: center;
    /* display: flow-root; */
  }

  .fc .fc-button-group {
    display: inline-flex;
    position: relative;
    vertical-align: middle;
    padding: 20px;
  }


}

// .fc-scrollgrid-sync-table tbody tr, .fc td, .fc th, .fc-scrollgrid-sync-table tbody tr td{
//   height: 20px !important;
// }

// .fc-daygrid-day-frame.fc-scrollgrid-sync-inner{
//   height: 20px !important;
// }

.fc-scrollgrid-sync-table {
  height: 0px !important;
}

.fc .fc-daygrid-day-frame {
  /* min-height: 100%; */
  position: relative;
  height: 6.3rem !important;
}

.fc .fc-timegrid-slot {
  border-bottom: 0;
  height: 60px !important;
}

.fc-event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.fc-daygrid-dot-event .fc-event-title:hover {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

@media (min-width: 920px) {
  // .fc .fc-scrollgrid-liquid {
  //   height: 50% !important;
  // }

  .fc-direction-ltr {
    direction: ltr;
    text-align: left;
    height: 510px !important;
  }
}

.accordion-body {
  padding: 0px !important;
}

.w-16 {
  width: 16px !important;
}

.h-16 {
  height: 16px !important;
}

.vertical-collpsed .navbar-brand-box .rotate-180 .toggle-icon {
  transform: rotateZ(180deg);
  transition: transform .3s ease;
}

.font-size-35 {
  font-size: 35px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  background: rgb(103 93 244 / 12%) !important;
}

.fixed-top {
  z-index: 200 !important;
}

.table-bordered {
  border: 1px solid #d9d9e0 !important;
}

.main-content {
  overflow: visible !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.icons-font1 {
  font-size: 18px;
}

.newaccountselect .ng-select-container {
  height: 45px !important;
}


.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em
}

.flag-icon:before {
  content: '\00a0'
}

.flag-icon.flag-icon-squared {
  width: 1em
}

// .fc-timegrid-event-harness>.fc-timegrid-event {
//   position: relative !important; 
// }

.flag-icon-us {
  background-image: url(../src/assets/images/united-states.svg)
}

.flag-icon-us.flag-icon-squared {
  background-image: url(../src/assets/images/united-states.svg)
}

.flag-icon-in {
  background-image: url(../src/assets/images/india.svg)
}

.flag-icon-in.flag-icon-squared {
  background-image: url(../src/assets/images/india.svg)
}

.flag-icon-ca {
  background-image: url(../src/assets/images/canada.svg)
}

.flag-icon-ca.flag-icon-squared {
  background-image: url(../src/assets/images/canada.svg)
}

.flag-icon-omn {
  background-image: url(../src/assets/images/oman-flag-icon.svg)
}

.flag-icon-omn.flag-icon-squared {
  background-image: url(../src/assets/images/oman-flag-icon.svg)
}

.form-select:focus {
  box-shadow: none !important;
}

.text-decoration {
  text-decoration: underline;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
  font-size: 14px;
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500 !important;
  font-size: 14px;
}

.fc-daygrid-block-event .fc-event-title {
  font-size: 14px;
}

.fc-daygrid-block-event .fc-event-time {
  font-weight: 400 !important;
}

.fc-v-event .fc-event-main-frame {
  font-size: 14px;
}

.fc-timegrid-event .fc-event-time {
  font-size: 14px !important;
}

.fc .fc-button-primary {
  /* background-color: var(--fc-button-bg-color); */
  /* border-color: var(--fc-button-border-color); */
  color: var(--fc-button-text-color);
  background-color: white !important;
  border-color: #006fbf !important;
  color: #006fbf !important;
  text-transform: capitalize !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  box-shadow: none !important;
  background: #006fbf !important;
  color: white !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px !important;
  height: 8px !important;
  /* padding: 5px; */
}

.custom-scroll::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #dddddd !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  display: flex !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  content: "";
  background: #006fbf !important;
}

.modal-background-head {
  background-color: #006fbf;
}

.min-w-45 {
  min-width: 45% !important;
}

.w-15 {
  width: 15% !important;
}

.opened .colors>* {
  display: none !important;
}

.opened .colors>*:nth-child(-n+10),
.opened .colors>*:last-child {
  display: flex !important;
}



.checkboxborder {
  border-color: #99a4c7 !important;
  box-shadow: none !important
}

#questiontype .ng-dropdown-panel .ng-dropdown-panel-items.scroll-host {
  position: absolute !important;
  width: inherit;
}

.w-200px {
  width: 200px !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator {
  padding: 60px 0 10px 0;
  display: none !important;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator {
  padding: 24px 0 10px 0;
  display: none !important;
}

.background-blue {
  background-color: #006fbf;
}

.inputfield-focus:focus {
  border: 2px solid #75a9f9 !important;
}

.fs-14 {
  font-size: 14px !important;
}

.h-30 {
  height: 30px;
}

.w-80 {
  width: 80%;
}

.w-55 {
  width: 55%;
}

.w-180px {
  width: 180px !important;
}

.ng5-slider .ng5-slider-pointer {
  z-index: 1 !important;
}

.checkboxwrite1:checked[type=checkbox] {
  border: #f04c4c !important;
  background-color: #f04c4c !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='none' d='M0 0h24v24H0z'/%3e%3cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='white' style='stroke-width: 1.5px; stroke: white; stroke-linecap: round; stroke-linejoin: round;' /%3e%3c/svg%3e") !important;

}

.checkboxwrite1 {
  color: #006fbf !important;
  border-color: #006fbf !important;
  border-width: 1.5px !important;
  border-style: solid !important;
  font-weight: bold !important;
  font-size: 16px !important;
  text-align: left !important;
}

#pickoneaudit .ng-select-container .ng-value-container .ng-value {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.icons-square {
  color: #97A2AE;
  background-color: transparent;
  border: 1px solid #b5b2b2;
  // border: 1px solid #006fbf;
  // opacity: 1;
  letter-spacing: 0.5px;
  font-size: 13px;
  opacity: 1;
  padding: 5px;
  border-radius: 5px;
}

.icons-square:hover {
  background-color: rgb(84 145 201 / 24%);
  border: 1px solid #8c8a8a;
}

.icons-edit {
  color: #2828c4 !important;
}

.icons-delete {
  color: #f20606f2 !important;
}

.icons-archive {
  color: #b87777 !important;
}

.icons-unarchive {
  color: orange !important;
}

.icons-history {
  color: #5BC0DE !important;
}

.icons-report {
  color: #D9534F !important;
}

.footer {
  height: 0 !important;
  position: fixed !important;
  z-index: 1;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 0 !important;
}

.panel-zindex .ng-dropdown-panel {
  z-index: 1 !important;
}

.vertical-collpsed .main-content {
  margin-left: 0px !important;
}

.vertical-collpsed .footer {
  left: 0 !important;
}

.fc .fc-scrollgrid-liquid {
  height: calc(100vh - 202px) !important;
}

@media (min-width: 821px) {
  .d-sm-block1 {
    display: block !important;
  }

  .d-sm-none1 {
    display: none !important;
  }

  .selectDropDown {
    width: 167px;
  }
}

@media (max-width: 821px) {
  .d-sm-block1 {
    display: none !important;
  }

  .d-sm-none1 {
    display: block !important;
  }
}

.w-10 {
  width: 10%;
}

#schedularselect .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child { 
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.overflow-y-hidden{
  overflow-y: hidden !important;
}

.overflow-x-auto{
  overflow-x: auto !important;
}

.w-90{
  width: 90%;
}

.my-custom-ag-grid .ag-theme-alpine .ag-body .ag-row {
  border-bottom: 1px solid #006fbf !important;
}
.my-custom-ag-grid .ag-theme-alpine .ag-cell {
  border-right: 1px solid #006fbf !important;
}
.my-custom-ag-grid .ag-theme-alpine .ag-cell:first-child {
  background-color: #d4e3fc !important;
  border-left: 1px solid #006fbf;
}
.my-custom-ag-grid .ag-theme-alpine .ag-header {
  background-color: #d4e3fc !important;
}
.my-custom-ag-grid .ag-theme-alpine .ag-row:nth-child(even) .ag-cell:not(:first-child) {
  background-color: #f0f0f0; 
}
.my-custom-ag-grid .ag-theme-alpine .ag-header-cell:first-child {
  background-color: #ffffff;
}

.my-custom-ag-grid .ag-theme-alpine .ag-row:nth-child(odd) .ag-cell:not(:first-child) {
  background-color: #ffffff; 
}

.my-custom-ag-grid .ag-header-cell-label {
  justify-content: center !important;
}

.my-custom-ag-grid .ag-header-cell{
  border-top: 1px solid #006fbf;
  border-left: 1px solid #006fbf;
  border-right: 1px solid #006fbf;
  border-bottom: 1px solid #006fbf;
}
.my-custom-ag-grid .ag-header-cell-text{
  font-weight: 400;
}

.toggle-switch:checked {
  background-color: #28b528 !important;
  border-color: #28b528 !important;
}

.modal {
  --bs-modal-zindex: 100000000 !important;
}


.ng-select .ng-select-container {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 30px !important;
  align-items: center;
}

.ng-select.ng-select-single .ng-select-container {
  height: 34.52px !important;
}